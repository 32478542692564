import { render, staticRenderFns } from "./StatusAssistance.vue?vue&type=template&id=38c2ac58&scoped=true&"
import script from "./StatusAssistance.vue?vue&type=script&lang=js&"
export * from "./StatusAssistance.vue?vue&type=script&lang=js&"
import style0 from "./StatusAssistance.vue?vue&type=style&index=0&id=38c2ac58&lang=scss&scoped=true&"
import style1 from "./StatusAssistance.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38c2ac58",
  null
  
)

export default component.exports